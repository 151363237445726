<template>
  <div class="Audio">
    <audio
      :src="audioPath"
      :autoplay="autoplay"
      @pause="setPause"
      @play="setPlay"
      @timeupdate="updateRange"
    />
    <div class="Player">
      <img
        :src="icon"
        alt="play/pause"
        @click="toggleIcon"
      >
      <label for="audio-range" />
      <input
        id="audio-range"
        class="bar"
        type="range"
        step="0.01"
        min="0"
        :max="max"
        :value="value"
        @change="updateAudio"
      >
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
export default {
    name: 'AudioPlayer',
    props: {
        audioPath: {
            type: String,
            default() {
                return require('../assets/audio/moonlight.mp3');
            },
        },
        autoplay: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },
    data() {
        return {
            playIcon: require('../assets/play.svg'),
            pauseIcon: require('../assets/pause.svg'),
            icon: require('../assets/play.svg'),
            value: 0,
            max: 100,
            currentTime: 0,
        };
    },
    methods: {
        setPlay() {
            if (this.icon !== this.pauseIcon) this.icon = this.pauseIcon;
        },
        setPause() {
            if (this.icon !== this.playIcon) this.icon = this.playIcon;
        },
        toggleIcon(event) {
            const audio = event.target.parentElement.parentElement
                .getElementsByTagName('audio')[0];

            console.log(audio.paused);

            if (!audio.paused) {
                this.setPlay();
                audio.pause();
            } else {
                this.setPause();
                audio.play();
            }
        },

        updateRange(event) {
            const { currentTime } = event.target;
            const { duration } = event.target;

            this.max = duration;
            this.value = currentTime;
        },
        updateAudio(event) {
            const currentValue = event.target.value;
            const audio = event.target.parentElement.parentElement
                .getElementsByTagName('audio')[0];

            audio.pause();
            audio.currentTime = currentValue;
        },
    },
};
</script>

<style scoped>

.Audio {
  width: 100%;
  align-self: center;
}

.Audio .Player {
  display: flex;
  align-items: center;
  justify-content: left;
}

.Audio .Player img {
  width: 15px;
  height: auto;
  cursor: pointer;
}

.Audio .Player .bar {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 5px;
  border-radius: 50px;
  background: var(--red);
  margin-left: 15px;
  position: relative;
}
.Audio .Player .bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--red);
  width: 15px;
  height: 15px;
  border-radius: 50px;
}

@media (max-width: 768px) {
  .Audio {
    width: 60vw;
  }
}
</style>
