import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        playing: false,
        firstUser: true,
    },
    mutations: {
        setPlaying(state, payload) {
            state.playing = payload;
        },
        setFirstUser(state, payload) {
            state.firstUser = payload;
        },
    },
    actions: {
    },
    modules: {
    },
});
