<template>
  <div id="app">
    <main-header />
    <transition
      name="fade"
      mode="out-in"
    >
      <router-view />
    </transition>
    <Footer />
  </div>
</template>

<script>
import MainHeader from './components/MainHeader.vue';
import Footer from './components/Footer.vue';

export default {
    components: {
        MainHeader,
        Footer,
    },
};
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}</style>
