<template>
  <footer class="footer">
    <div class="content">
      <p>© 2024 CHBlacksmith</p>
    </div>
  </footer>
</template>

<script>
export default {
    name: 'Footer',
};
</script>

<style scoped>
.footer {
  width: 100%;
  position: relative;
  /*background-color: #333; !* Um pouco mais claro que #000 *!*/
  color: white;
  text-align: center;
  padding: 20px 0;
  text-transform: uppercase;
  z-index: 100;
}
.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-family: canter, sans-serif !important;
}
</style>
